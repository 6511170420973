import React, { useState } from "react";
import { symbolImageLink } from "../symbols/symbolImageLink";
import { getRowsForGame } from "../rows/rows";
import "./AsymmetricGridRenderer.css";

function AsymmetricGridRendererNotAvalanche(stage, data) {
  const { payouts, reel_window, multipliers, wild_mask, bonus_game } =
    stage.stage;
  let multiplierIndex = 0;

  const renderSymbol = (symbol) => {
    let displayText;
    if (symbol === 12) {
      if (multipliers.length === 0) {
        return <span className="winNumber red-outline">WILD</span>;
      }

      const multiplier = multipliers[multiplierIndex];
      if (stage.gameName === "coral-cash") {
        displayText = `X${multiplier}`;
      } else {
        displayText =
          stage.gameName === "animals-of-egypt" && multiplier === 1
            ? null
            : multiplier === 1
            ? "WILD"
            : `X${multiplier}`;
      }

      if (multiplierIndex < multipliers.length - 1) {
        multiplierIndex++;
      }

      return (
        <span
          style={{
            margin: "auto 20px auto 10px",
            fontSize: displayText === "WILD" ? "28px" : "52px",
          }}
          className="winNumber"
        >
          {displayText}
        </span>
      );
    }
    return null;
  };

  const tableRows = (
    <div className="table-div">
      <div className="columns-container">
        {reel_window?.map((column, columnKey) => {
          const reversedColumn = [...column].reverse();

          let symbol, symbolIndex, brightness;
          return (
            <div className="column-container" key={columnKey}>
              {getRowsForGame(stage.gameName)?.map((row, rowKey) => {
                if (stage.gameName === "anubis-secret") {
                  symbol = reversedColumn[row];
                  symbolIndex = reversedColumn.indexOf(symbol);
                } else {
                  symbol = column[row];
                  symbolIndex = column.indexOf(symbol);
                }
                const reversedSymbolIndex = column.length - 1 - symbolIndex;

                const reverseWildMask =
                  wild_mask &&
                  wild_mask.map((mask) => {
                    return [...mask].reverse();
                  });

                const isSymbolInWildMask =
                  wild_mask && reverseWildMask[columnKey][row];

                const isSymbolInWinLine = payouts.values?.some((payout) => {
                  const subIndexes = payout.indexes?.[columnKey];
                  return (
                    Array.isArray(subIndexes) &&
                    subIndexes.includes(reversedSymbolIndex)
                  );
                });

                const size =
                  column.length >= 2 && column.length <= 6
                    ? column.length - 1
                    : 6;
                brightness = isSymbolInWinLine ? 1 : 0.4;

                if (
                  stage.gameName === "cat-mansion" &&
                  symbol === 13 &&
                  payouts?.bonus_choice
                ) {
                  brightness = 1;
                }

                if (
                  stage.gameName === "anubis-secret" &&
                  symbol === 15 &&
                  bonus_game
                ) {
                  brightness = 1;
                }

                if (
                  stage.gameName === "buffalo-run" &&
                  symbol === 13 &&
                  payouts?.bonus_choice
                ) {
                  brightness = 1;
                }

                return (
                  <div
                    className={`${reversedSymbolIndex} symbol-container symbol-size-${size}`}
                    key={`${rowKey}-${columnKey}`}
                    style={{
                      filter: `brightness(${brightness})`,
                      display: "flex",
                      justifyContent: multipliers
                        ? multipliers[multiplierIndex] === 1
                          ? "flex-start"
                          : "center"
                        : null,
                      alignItems: "center",
                      backgroundImage:
                        stage.gameName === "valor-vs-anarika"
                          ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                              stage.gameName
                            )}/${
                              stage?.data?.request?.engine_params?.type ===
                              "zeus"
                                ? "zeus"
                                : "hades"
                            }/${String(symbol)?.toLowerCase()}.png)`
                          : isSymbolInWildMask
                          ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                              stage.gameName
                            )}/${String(18).toLowerCase()}_${size}.png)`
                          : symbol
                          ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                              stage.gameName
                            )}/${String(symbol).toLowerCase()}_${size}.png)`
                          : "none",
                    }}
                  >
                    {multipliers && stage.gameName !== "buffalo-run"
                      ? renderSymbol(symbol)
                      : null}
                    {!symbolImageLink(stage.gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );

  return tableRows;
}

export default AsymmetricGridRendererNotAvalanche;
