import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createOrganization } from "../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

export const OrganizationModal = ({ setShowModal, onSettled }) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const types = [
    {
      label: "Operator",
      value: "operator",
    },
    {
      label: "Integrator",
      value: "integrator",
    },
    {
      label: "Provider",
      value: "provider",
    },
  ];

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      status: 1,
      type: "",
    },
  });

  const selectedType = watch("type");

  const { mutate } = useMutation(createOrganization, {
    onSuccess: () => {
      toastAlert("success");
      onSettled();
      setShowModal(false);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setShowModal(false);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Organization was created successfully`
          : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    formData.status = parseInt(formData.status);
    setLoading(true);
    mutate(formData);
  });

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Add Organization</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={(e) => {
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label className="">Name</Form.Label>
            <Form.Control
              placeholder="Enter name"
              type="text"
              {...register("name", {
                required: true,
                validate: (value) => value?.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.name}
              isValid={isSubmitted && !errors?.name}
            />
            {errors?.name && <div className="invalid-feedback">Required</div>}
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Type</Form.Label>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { invalid },
              }) => (
                <>
                  <Select
                    className={`form-control${
                      isSubmitted && invalid ? " is-invalid" : ""
                    }${isSubmitted && !invalid ? " is-valid" : ""}`}
                    showSearch
                    value={value || null}
                    onChange={onChange}
                    placeholder="Select type..."
                    popupClassName="modal-select"
                    optionFilterProp="children"
                    options={
                      types?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      })) || []
                    }
                  />
                  {invalid && (
                    <div className="invalid-feedback">Type is required</div>
                  )}
                </>
              )}
            />
          </Form.Group>

          {selectedType === "provider" && (
            <Form.Group className="form-group">
              <Form.Label className="">Public id</Form.Label>
              <Form.Control
                placeholder="Enter id"
                type="text"
                {...register("public_id", {
                  required: true,
                  validate: (value) => value?.trim() !== "",
                })}
                isInvalid={isSubmitted && errors?.name}
                isValid={isSubmitted && !errors?.name}
              />
              {errors?.name && <div className="invalid-feedback">Required</div>}
            </Form.Group>
          )}

          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? (
              <span className="text-white">Loading...</span>
            ) : (
              "Create"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
