//heronbyte
export const preciousBugsPaylines = [
  [5, 6, 7, 8, 9],
  [0, 1, 2, 3, 4],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [5, 1, 2, 3, 9],
  [5, 11, 12, 13, 9],
  [10, 11, 7, 13, 14],
  [0, 1, 7, 3, 4],
  [5, 1, 7, 3, 9],
];

export const stonesOfMagicPaylines = [
  [5, 6, 7, 8, 9],
  [0, 1, 2, 3, 4],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [0, 1, 7, 3, 4],
  [10, 11, 7, 13, 14],
  [5, 11, 12, 13, 9],
  [5, 1, 2, 3, 9],
  [5, 1, 7, 3, 9],
  [5, 11, 7, 13, 9],
  [0, 6, 2, 8, 4],
  [10, 6, 12, 8, 14],
  [5, 6, 2, 8, 9],
  [5, 6, 12, 8, 9],
  [0, 6, 7, 8, 4],
  [10, 6, 7, 8, 14],
  [0, 6, 12, 13, 14],
  [10, 6, 2, 3, 4],
  [0, 11, 2, 13, 4],
];

//bf
export const smashingHotPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
];

export const smashingHot20Paylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [0, 1, 7, 13, 14],
  [10, 11, 7, 3, 4],
  [5, 1, 7, 13, 9],
  [5, 11, 7, 3, 9],
  [5, 1, 2, 8, 4],
  [5, 11, 12, 8, 14],
  [0, 6, 2, 3, 9],
  [10, 6, 12, 13, 9],
  [0, 11, 2, 13, 4],
  [10, 1, 12, 3, 14],
  [5, 1, 12, 3, 9],
  [5, 11, 2, 13, 9],
  [0, 6, 7, 8, 4],
  [10, 6, 7, 8, 14],
  [0, 11, 12, 13, 4],
];

export const bookOfDynastyPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [0, 1, 7, 13, 14],
  [10, 11, 7, 3, 4],
  [5, 11, 12, 13, 9],
  [5, 1, 2, 3, 9],
  [10, 6, 7, 8, 4],
];

export const majesticCrownPaylines = [
  [0, 1, 2, 3, 4], //0
  [5, 6, 7, 8, 9], //1
  [10, 11, 12, 13, 14], //2
  [0, 6, 12, 8, 4], //3
  [10, 6, 2, 8, 14], //4
  [10, 11, 7, 3, 4], //5
  [10, 11, 7, 13, 14], //6
  [5, 11, 12, 13, 9], //7
  [5, 1, 2, 3, 9], //8
  [5, 1, 7, 3, 9], //9
];

export const wildWestJohnPaylines = [
  [0, 1, 2],
  [0, 1, 5],
  [0, 1, 8],
  [0, 4, 2],
  [0, 4, 5],
  [0, 4, 8],
  [0, 7, 2],
  [0, 7, 5],
  [0, 7, 8],
  [3, 1, 2],
  [3, 1, 5],
  [3, 1, 8],
  [3, 4, 2],
  [3, 4, 5],
  [3, 4, 8],
  [3, 7, 2],
  [3, 7, 5],
  [3, 7, 8],
  [6, 1, 2],
  [6, 1, 5],
  [6, 1, 8],
  [6, 4, 2],
  [6, 4, 5],
  [6, 4, 8],
  [6, 7, 2],
  [6, 7, 5],
  [6, 7, 8],
];

export const blazingClovers40Paylines = [
  [5, 6, 7, 8, 9], // 1
  [10, 11, 12, 13, 14], // 2
  [0, 1, 2, 3, 4], // 3
  [15, 16, 17, 18, 19], // 4
  [5, 11, 17, 13, 9], // 5
  [10, 6, 2, 8, 14], // 6
  [0, 6, 12, 8, 4], // 7
  [15, 11, 7, 13, 19], //8
  [5, 1, 2, 3, 9], //9
  [10, 16, 17, 18, 14], // 10
  [0, 6, 7, 8, 4], // 11
  [15, 11, 12, 13, 14], // 12
  [5, 1, 7, 13, 9], // 13
  [10, 16, 12, 8, 14], // 14
  [5, 11, 7, 3, 9], // 15
  [10, 6, 12, 18, 14], //16
  [5, 1, 7, 3, 9], //17
  [10, 16, 12, 18, 14], // 18
  [0, 6, 2, 8, 4], // 19
  [15, 11, 17, 13, 19], // 20
  [5, 6, 12, 18, 19], // 21
  [10, 11, 7, 3, 4], // 22
  [0, 1, 7, 13, 14], // 23
  [15, 16, 12, 8, 9], //24
  [5, 6, 2, 8, 9], //25
  [10, 11, 17, 13, 14], // 26
  [0, 1, 7, 3, 4], // 27
  [15, 16, 12, 18, 19], // 28
  [5, 6, 7, 13, 19], // 29
  [10, 11, 12, 4, 8], // 30
  [0, 1, 2, 8, 14], // 31
  [15, 16, 17, 13, 9], //32
  [5, 11, 17, 18, 19], //33
  [10, 6, 2, 3, 4], // 34
  [0, 6, 12, 13, 14], // 35
  [15, 11, 7, 8, 9], // 36
  [0, 1, 7, 13, 19], // 37
  [15, 16, 12, 8, 4], // 38
  [0, 6, 12, 18, 19], // 39
  [15, 11, 7, 3, 4], // 40
];

//lemon
export const burningWinPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
];

export const bookOfJonesPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [5, 1, 2, 3, 9],
  [5, 11, 12, 13, 9],
  [0, 1, 7, 13, 14],
  [10, 11, 7, 3, 4],
  [5, 11, 7, 3, 9],
];
