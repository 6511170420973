import React, { useEffect, useState } from "react";
import { Badge, Calendar } from "antd";
import "./Calendar.scss";
import { useCurrency } from "../../../contexts";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getReportsByDays } from "../../../services/reports";

const CalendarComponent = () => {
  //@ts-ignore
  const { currency } = useCurrency();

  const [data, setData] = useState(null);

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );
  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const [params, setParams] = useState({
    starting_from: "",
    ending_at: "",
    integrator: "",
    operator: "",
  });

  const { isLoading, refetch, isRefetching } = useQuery(
    [
      "by_days-reports",
      {
        currency,
        ...params,
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getReportsByDays,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  useEffect(() => {
    const today = new Date();
    const startOfPreviousMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );

    const formatDate = (date) => {
      return (
        date.toISOString().slice(0, 19).replace("T", " ") +
        (new Date().getTimezoneOffset() / -60 > 0 ? "+" : "-") +
        ("0" + Math.abs(new Date().getTimezoneOffset() / -60)).slice(-2) +
        ":00"
      );
    };

    setParams({
      starting_from: formatDate(startOfPreviousMonth),
      ending_at: formatDate(today),
      integrator: "",
      operator: "",
    });
  }, []);

  useEffect(() => {
    if (params.starting_from) {
      refetch();
    }
  }, [params.starting_from, selectedIntegrator, selectedOperator]);

  const getListData = (value) => {
    const listData = [];
    const dateString = value.format("YYYY-MM-DD");

    if (data && data.length > 0) {
      const report = data.find((item) => item.day.startsWith(dateString));

      if (report) {
        listData.push({
          type: "success",
          content: `Revenue: ${report.revenue}`,
        });
      }
    }

    return listData;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const monthCellRender = (value) => {
    const num = value.month() === 8 ? 1394 : null;
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  return (
    <Calendar
      dateCellRender={dateCellRender}
      monthCellRender={monthCellRender}
      mode="month"
    />
  );
};

export default CalendarComponent;
