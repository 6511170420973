export const getRowsForGame = (gameName) => {
  const gameRowsMapping = {
    "asgard-party": [...Array(9).keys()],
    "sweet-mystery-flexiways": [...Array(9).keys()],
    "ego-draconis": [...Array(9).keys()],
    "green-gold": [...Array(4).keys()],
    "blazing-clovers-40-94": [...Array(4).keys()],
    "blazing-clovers-40-96": [...Array(4).keys()],
    "valor-vs-anarika": [...Array(5).keys()],
    "toucan-fruits": [...Array(5).keys()],
    "magic-moonlight": [...Array(7).keys()],
    "coral-reef-flexiways": [...Array(7).keys()],
    "cat-mansion": [...Array(7).keys()],
    "animals-of-egypt": [...Array(7).keys()],
    "buffalo-run": [...Array(7).keys()],
    "coral-cash": [...Array(7).keys()],
    "anubis-secret": [...Array(7).keys()],
    "viking-saga-flexiways": [...Array(6).keys()],
    "cleos-riches-flexiways": [...Array(7).keys()],
    "great-bear-multiways": [...Array(7).keys()],
    "hunters-luck": [...Array(7).keys()],
    "royal-sands": [...Array(7).keys()],
    "witch-of-fortune-multiways": [...Array(7).keys()],
    "lucky-skulls-bonanza": [...Array(5).keys()],
    "rsh-gates-of-olympus": [...Array(5).keys()],
    "gamblers-gala": [...Array(5).keys()],
    "gates-of-war": [...Array(5).keys()],
    "gates-of-ekremabi": [...Array(5).keys()],
    "rush-moji": [...Array(7).keys()],
    "tutti-frutti": [...Array(7).keys()],
    "candy-crashout": [...Array(5).keys()],
    "cowboy-cashout": [...Array(5).keys()],
    "lucky-santa-bonanza": [...Array(5).keys()],
    "jelly-joy-bonanza": [...Array(7).keys()],
    "irish-riches-bonanza": [...Array(7).keys()],
    "clover-cluster": [...Array(7).keys()],
  };

  const defaultRows = [...Array(3).keys()];

  return gameRowsMapping[gameName] || defaultRows;
};
