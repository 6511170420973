export const formatGameName = (name) => {
  if (name === "stonesofmagic") {
    return "Stones of magic";
  } else if (name === "secrettotems") {
    return "Secret totems";
  } else if (name === "asgardparty") {
    return "Asgard party";
  } else if (name === "preciousbugs") {
    return "Precious bugs";
  } else {
    const words = name
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
  }
};

export const FormattedNumber = ({ value }) => {
  if (typeof value === "number") {
    return value.toLocaleString();
  }
  return value;
};

export const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        color: "rgba(255, 255, 255, 0)",
      },
      ticks: {
        color: "#9B9B9B",
        font: {
          size: 13,
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "#9B9B9B",
      },
      ticks: {
        color: "#9B9B9B",
        font: {
          size: 12,
        },
      },
    },
  },
};
