import React from "react";

export const GameResultPlinko = ({ data }) => {
  // const { path } = data.spin;

  // const rows = 17;
  // const dotSpacing = 22;
  // const maxDots = 25;
  // const topOffset = 15;

  // const generateDots = () => {
  //   const dots = [];
  //   for (let row = 0; row < rows; row++) {
  //     const dotsInRow = row === 0 ? row + 3 : row + 3;
  //     const startOffset = (maxDots - dotsInRow) * (dotSpacing / 2);
  //     for (let col = 0; col < dotsInRow; col++) {
  //       const isPassed = path[row] === col;
  //       dots.push(
  //         <div
  //           key={`${row}-${col}`}
  //           style={{
  //             position: "absolute",
  //             top: `${topOffset + row * dotSpacing}px`,
  //             left: `${startOffset + col * dotSpacing}px`,
  //             width: "12px",
  //             height: "12px",
  //             backgroundColor: isPassed ? "orange" : "white",
  //             borderRadius: "50%",
  //             boxShadow: isPassed ? "0px 0px 5px 2px orange" : "none",
  //           }}
  //         />
  //       );
  //     }
  //   }
  //   return dots;
  // };
  const renderWinResultsTable = () => {
    const activePayout = data?.spin;
    return (
      <table
        className="game-stats-info__stats-class mt-0"
        id="win_results_table"
      >
        <thead>
          <tr className="text-center">
            <td colSpan={4} className="py-3">
              Winning result
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Wager</th>
            <th>Award</th>
            <th>Ball multiplier</th>
            <th>Box multiplier</th>
          </tr>
          {activePayout && (
            <tr className="text-center">
              <td>{activePayout.wager / 1000}</td>
              <td>{activePayout.award / 1000}</td>
              <td>{activePayout.multiplier}</td>
              <td>{activePayout.box_multiplier / 10}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {/* <div
        style={{
          position: "relative",
          width: `${maxDots * dotSpacing}px`,
          height: `${rows * dotSpacing + topOffset}px`,
          borderRadius: "10px",
          margin: "10px auto",
          overflow: "hidden",
          backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/deep-sea-plinko/background.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        {generateDots()}
      </div> */}
      <div>{renderWinResultsTable()}</div>
    </>
  );
};
