import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import { CurrencyContextProvider, ProfileContextProvider } from "./contexts";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "./pages/Authentication/auth";
import "./index.scss";
import Loader from "./components/Loader/Loader";
import Users from "./pages/Users-Roles/Users";
import Dashboard from "./pages/Dashboard";
import Roles from "./pages/Users-Roles/Roles";
import UsersReport from "./pages/GameData/Users/UsersReport";
import Sessions from "./pages/GameData/Sessions";
import SessionDetail from "./pages/SessionDetail";
import Gameplays from "./pages/GameData/Gameplays";
import GameplayDetail from "./pages/SpinDetail";
import Permissions from "./pages/Permissons";
import Settings from "./pages/Settings";
import AuthLogin from "./pages/Authentication/Login";
import CurrenciesMultipliers from "./pages/Currencies/CurrenciesMultipliers/CurrenciesMultipliers";
import WagerSets from "./pages/GamesManagement/WagerSets/WagerSets";
import GamesInfo from "./pages/GamesManagement/GamesInfo/GamesInfo";
import GameDetail from "./pages/GamesManagement/GameDetail/GameDetail";
import UserDetails from "./pages/UserDetails/UserDetails";
import ByGame from "./pages/FinancialReports/ByGame/ByGame";
import ByCountry from "./pages/FinancialReports/ByCountry/ByCountry";
import Downloads from "./pages/Downloads/Downloads";
import Organizations from "./pages/Organizations/Organizations";
import { NotificationProvider } from "./contexts/notificationContext";
import { WebSocketProvider } from "./contexts/websocketContext";
import { DemoContextProvider } from "./contexts/demoContext";
import { DownloadProvider } from "./contexts/downloadContext";
import Integrations from "./pages/GamesManagement/Integrations/Integrations";
import ResetPassword from "./pages/Authentication/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/Authentication/ForgotPassword/ForgotPassword";
import Lobby from "./pages/Lobby/Lobby";
import History from "./pages/History/history";
import GameRoundHistory from "./pages/History/GameRoundHistory";
import Currencies from "./pages/Currencies/Currencies";
import CalendarComponent from "./pages/FinancialReports/Calendar/Calendar";
const App = React.lazy(() => import("../src/App"));

const AppComponent = () => {
  let userData = null;
  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: 0 } },
  });
  const loggedIn = window
    ? window.localStorage.getItem("access_token") &&
      moment(window.localStorage.getItem("expired_at")).diff(moment()) > 0
    : false;

  const handleUserDataCallback = (data) => {
    if (data?.account) {
      userData = data.account;
    }
  };
  const UserDashBoardCondition = () => {
    if (userData?.email === "admin") {
      return <Dashboard />;
    }
    if (
      userData?.permissions.find((permision) => permision.name === "Dashboard")
    ) {
      return <Dashboard />;
    } else {
      return <Navigate to="/lobby" />;
    }
  };
  return (
    <QueryClientProvider client={queryClient}>
      <DemoContextProvider>
        <CurrencyContextProvider>
          <ProfileContextProvider userData={handleUserDataCallback}>
            <WebSocketProvider>
              <DownloadProvider>
                <NotificationProvider>
                  <BrowserRouter>
                    <React.Suspense fallback={<Loader />}>
                      <Routes>
                        {loggedIn ? (
                          <Route path={`/`} element={<App />}>
                            <Route
                              path={`/`}
                              element={<UserDashBoardCondition />}
                            />
                            <Route path="/reports">
                              {/* <Route
                                path={`/reports/calendar`}
                                element={<CalendarComponent />}
                              /> */}
                              <Route
                                path={`/reports/by-game`}
                                element={<ByGame />}
                              />
                              <Route
                                path={`/reports/by-country`}
                                element={<ByCountry />}
                              />
                              <Route
                                path={`/reports/sessions`}
                                element={<Sessions />}
                              />
                              <Route
                                path={`/reports/sessions/:id`}
                                element={<SessionDetail />}
                              />
                              <Route
                                path={`/reports/gameplays`}
                                element={<Gameplays />}
                              />
                              <Route
                                path={`/reports/users`}
                                element={<UsersReport />}
                              />
                              <Route
                                path={`/reports/downloads`}
                                element={<Downloads />}
                              />
                              <Route
                                path={`/reports/gameplays/:id`}
                                element={<GameplayDetail />}
                              />
                              <Route
                                path={`/reports/users/:id`}
                                element={<UserDetails />}
                              />
                            </Route>
                            <Route
                              path={"/games/info"}
                              element={<GamesInfo />}
                            />
                            <Route
                              path={`/games/info/:id`}
                              element={<GameDetail />}
                            />
                            <Route path={"/lobby"} element={<Lobby />} />
                            <Route
                              path={"/wager-sets"}
                              element={<WagerSets />}
                            />
                            <Route
                              path={"/organizations"}
                              element={<Organizations />}
                            />
                            <Route
                              path={`/currencies`}
                              element={<Currencies />}
                            />
                            <Route
                              path={`/currencies/multipliers`}
                              element={<CurrenciesMultipliers />}
                            />
                            <Route path={`/settings`} element={<Settings />} />
                            <Route
                              path={`/integrations`}
                              element={<Integrations />}
                            />
                            <Route path="/bo-accounts">
                              <Route
                                path={`/bo-accounts/`}
                                element={<Users />}
                              />
                              <Route
                                path={`/bo-accounts/roles`}
                                element={<Roles />}
                              />
                              <Route
                                path={`/bo-accounts/permissions`}
                                element={<Permissions />}
                              />
                            </Route>
                          </Route>
                        ) : (
                          <Route path={`/`} element={<Auth />}>
                            <Route index element={<AuthLogin />} />
                            <Route
                              path={`/forgot-password`}
                              element={<ForgotPassword />}
                            />
                            <Route
                              path={`/reset-password`}
                              element={<ResetPassword />}
                            />
                          </Route>
                        )}
                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route
                          path={`/history/session/:id`}
                          element={<History />}
                        />
                        <Route
                          path={`/history/round/:round_id`}
                          element={<GameRoundHistory />}
                        />
                      </Routes>
                    </React.Suspense>
                  </BrowserRouter>
                </NotificationProvider>
              </DownloadProvider>
            </WebSocketProvider>
          </ProfileContextProvider>
        </CurrencyContextProvider>
      </DemoContextProvider>
    </QueryClientProvider>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <AppComponent />
  </React.StrictMode>,
  document.getElementById("root")
);
