import React, { forwardRef } from "react";
import { Accordion, Carousel } from "react-bootstrap";
import { processWinlinesTable } from "../tableCalculations/processWinlinesTable";
import { getRowsForGame } from "../../helpers/rows/rows";
import AsymmetricGridRendererAvalanche from "../../helpers/renderers/AsymmetricGridRendererAvalanche";

const WinlinesCarousel = forwardRef(({ stage, gameName }, ref) => {
  const coinRespinApple = () => {
    if (stage?.free_game_spin) {
      return (
        <Accordion>
          {stage?.free_game_spin?.map((spin, key) => {
            return (
              <Accordion.Item eventKey={key} key={key}>
                <Accordion.Header>Free spin - {key + 1}</Accordion.Header>
                <Accordion.Body>
                  {getRowsForGame(gameName)?.map((row, rowKey) => (
                    <tr key={rowKey}>
                      {spin?.window?.map((column, columnKey) => {
                        let symbol, brightness;

                        symbol = column[row];

                        let isSymbolInWinLine = true;

                        brightness = isSymbolInWinLine ? 1 : 0.4;

                        return (
                          <td
                            key={columnKey}
                            style={{
                              width: "73px",
                              filter: `brightness(${brightness})`,
                              backgroundImage:
                                symbol === 1 && gameName === "apple-temptation"
                                  ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/apple-temptation/5.png)`
                                  : null,

                              backgroundSize: "cover",
                            }}
                          >
                            {gameName === "apple-temptation" ? (
                              <div
                                className="symbol-multiplier"
                                style={{
                                  border: "none",
                                  height: "55px",
                                }}
                              ></div>
                            ) : null}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      );
    }
    return null;
  };

  const renderWinResultsTable = (activeKey) => {
    const activePayout = stage.payouts?.values[activeKey];
    return (
      <table
        className="game-stats-info__stats-class mt-0"
        id="win_results_table"
      >
        <thead>
          <tr className="text-center">
            <td colSpan={4} className="py-3">
              Winning result
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Payline</th>
            {activePayout?.valueWithMultiplier ||
            activePayout?.valueWithMultiplierBonus ? (
              <th>Award with multiplier</th>
            ) : (
              <th>Award</th>
            )}
            {activePayout?.multiplier || activePayout?.multiplierBonus ? (
              <th>Multiplier</th>
            ) : null}
            {activePayout.symbol ? <th>Symbol</th> : null}
          </tr>
          {activePayout && (
            <tr className="text-center">
              <td>
                {activePayout.payline
                  ? activePayout.payline + 1
                  : activeKey + 1}
              </td>
              <td>
                {stage.payouts.values[0].reel_multiplier
                  ? (activePayout.amount *
                      stage.payouts.values[0].reel_multiplier) /
                    1000
                  : activePayout?.valueWithMultiplier
                  ? activePayout?.valueWithMultiplier / 1000
                  : activePayout?.valueWithMultiplierBonus
                  ? activePayout?.valueWithMultiplierBonus / 1000
                  : activePayout.amount / 1000}
              </td>

              {activePayout?.multiplier ? (
                <td>{activePayout?.multiplier}</td>
              ) : activePayout?.multiplierBonus ? (
                <td>{activePayout?.multiplierBonus}</td>
              ) : null}
              {activePayout.symbol ? <td>{activePayout.symbol}</td> : null}
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  if (gameName === "viking-saga-flexiways") {
    return (
      <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
        {stage.payouts?.values?.map((value, key) => (
          <Carousel.Item key={key}>
            <table
              className={`game-stats-info__stats-class mt-0`}
              id="symbols_table"
            >
              <thead>
                <tr className="text-center">
                  {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                    <td colSpan={stage?.reel_window?.length} className="py-3">
                      Winline №{value.payline ? value.payline + 1 : key + 1}
                    </td>
                  )}
                </tr>
              </thead>
            </table>
            <AsymmetricGridRendererAvalanche
              stage={stage}
              gameName={gameName}
              winlineIndex={key}
            />
            {renderWinResultsTable(key)}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }

  const coinRespinDino = () => {
    if (stage?.free_game_spin) {
      return (
        <Accordion>
          {stage.free_game_spin.map((spin, key) => (
            <Accordion.Item eventKey={key} key={key}>
              <Accordion.Header>Free spin - {key + 1}</Accordion.Header>
              <Accordion.Body>
                {getRowsForGame(gameName)?.map((row, rowKey) => (
                  <tr key={rowKey}>
                    {spin.window.map((column, columnKey) => {
                      let symbol = column[row];
                      let brightness;
                      const resultsSymbol = column.map((value) => {
                        if (value !== 0) {
                          return value / 1000;
                        }
                        return null;
                      });

                      let isSymbolInWinLine = true; // Можно поменять на логику определения, если есть
                      brightness = isSymbolInWinLine ? 1 : 0.4;

                      return (
                        <td
                          key={columnKey}
                          style={{
                            width: "70px",
                            filter: `brightness(${brightness})`,
                            backgroundImage:
                              symbol !== 0 && gameName === "dino-reels"
                                ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/dino-reels/10.png)`
                                : null,
                            backgroundSize: "cover",
                          }}
                        >
                          {gameName === "dino-reels" ? (
                            symbol !== 0 ? (
                              <div
                                className="symbol-multiplier"
                                style={{
                                  border: "none",
                                  fontSize: "20px",
                                }}
                              >
                                {resultsSymbol[row]}
                              </div>
                            ) : null
                          ) : symbol !== 0 ? (
                            <div className="symbol-multiplier">
                              {resultsSymbol[row]}
                            </div>
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      );
    }
    return null;
  };

  return (
    <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
      {stage.payouts?.values?.map((value, key) => (
        <Carousel.Item key={key}>
          <table
            className={`game-stats-info__stats-class mt-0`}
            id="symbols_table"
          >
            <thead>
              <tr className="text-center">
                {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                  <td colSpan={stage?.reel_window?.length} className="py-3">
                    Winline №{value.payline ? value.payline + 1 : key + 1}
                  </td>
                )}
              </tr>
            </thead>
            {processWinlinesTable(stage, value, gameName, key)}
          </table>
          {gameName === "dino-reels" ? coinRespinDino() : null}
          {renderWinResultsTable(key)}
          {gameName === "apple-temptation" ? coinRespinApple() : null}
        </Carousel.Item>
      ))}
    </Carousel>
  );
});

export default WinlinesCarousel;
