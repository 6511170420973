import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { updateOrganization } from "../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

export const EditOrganizationModal = ({
  setShowModal,
  selectedData,
  setSelectedData,
  refetch,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const types = [
    {
      label: "Integrator",
      value: "integrator",
    },
    {
      label: "Provider",
      value: "provider",
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { mutate } = useMutation(updateOrganization, {
    onSuccess: () => {
      toastAlert("success");
      setShowModal(false);
      selectedData && setSelectedData(null);
      refetch();
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setShowModal(false);
      selectedData && setSelectedData(null);
      refetch();
    },
  });

  const updateSelectedData = (newData) => {
    setSelectedData((prevData) => ({ ...prevData, ...newData }));
  };

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Organization was updated successfully`
          : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    setLoading(true);
    formData.id = selectedData?.id;
    formData.status = parseInt(formData.status);
    mutate(formData);
  });

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  console.log(selectedData, "selectedData");

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Edit Organization</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={(e) => {
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label className="">Name</Form.Label>
            <Form.Control
              placeholder="Enter name"
              type="text"
              defaultValue={selectedData?.name}
              {...register("name", {
                required: true,
                validate: (value) => value?.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.name}
              isValid={isSubmitted && !errors?.name}
            />
            {errors?.name && <div className="invalid-feedback">Required</div>}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Status (set 0 or 1)</Form.Label>
            <Form.Control
              placeholder="Enter status"
              type="text"
              defaultValue={selectedData?.status}
              {...register("status", {
                required: true,
                validate: (value) =>
                  value?.trim() === "0" || value?.trim() === "1",
              })}
              isInvalid={isSubmitted && errors?.status}
              isValid={isSubmitted && !errors?.status}
            />
            {errors?.status && <div className="invalid-feedback">Required</div>}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Type</Form.Label>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Type is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  defaultValue={selectedData?.type}
                  className={`form-control ${errors.type ? "is-invalid" : ""}`}
                  optionFilterProp="children"
                  popupClassName={"custom-dropdown"}
                  dropdownStyle={{
                    zIndex: "9999999999",
                  }}
                  options={types}
                  placeholder="Select type..."
                  onChange={(value) => {
                    field.onChange(value);
                    updateSelectedData({ type: value });
                  }}
                />
              )}
            />
            {selectedData?.type === "provider" && (
              <Form.Group className="form-group">
                <Form.Label className="">Public id</Form.Label>
                <Form.Control
                  placeholder="Enter id"
                  type="text"
                  defaultValue={selectedData?.public_id}
                  {...register("public_id", {
                    required: true,
                    validate: (value) => value?.trim() !== "",
                  })}
                  isInvalid={isSubmitted && errors?.name}
                  isValid={isSubmitted && !errors?.name}
                />
                {errors?.name && (
                  <div className="invalid-feedback">Required</div>
                )}
              </Form.Group>
            )}
            {errors.type && (
              <div className="invalid-feedback">{errors.type.message}</div>
            )}
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? <span className="text-white">Loading...</span> : "Edit"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
